var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-card',{attrs:{"title":"Pikettplanung Automation","cardIcon":"fas fa-file-microchip"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('standby-duty-table',{ref:"standbyDutyAutomation",attrs:{"department":"Engineering"},scopedSlots:_vm._u([{key:"slot1",fn:function(){return [(
            _vm.selected !== undefined &&
            (_vm.selected.status === 1 || _vm.selected.status === 3) &&
            _vm.isRelevant
          )?_c('button',{staticClass:"btn btn-success mx-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.accept()}}},[_c('em',{staticClass:"fas fa-check"}),_vm._v(" Akzeptieren ")]):_vm._e()]},proxy:true},{key:"slot2",fn:function(){return [(
            _vm.selected !== undefined &&
            (_vm.selected.status === 1 || _vm.selected.status === 3) &&
            _vm.isRelevant
          )?_c('button',{staticClass:"btn btn-danger mx-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.decline()}}},[_c('em',{staticClass:"fas fa-times"}),_vm._v(" Ablehnen ")]):_vm._e()]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }