var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'task-error': _vm.appointment.appointmentData.taskError,
    'color-1':
      !_vm.appointment.appointmentData.taskError && _vm.appointment.appointmentData.colorCode === 1,
    'color-2':
      !_vm.appointment.appointmentData.taskError && _vm.appointment.appointmentData.colorCode === 2,
    'color-3':
      !_vm.appointment.appointmentData.taskError && _vm.appointment.appointmentData.colorCode === 3,
    'color-4':
      !_vm.appointment.appointmentData.taskError && _vm.appointment.appointmentData.colorCode === 4,
    'color-5':
      !_vm.appointment.appointmentData.taskError && _vm.appointment.appointmentData.colorCode === 5,
    'color-6':
      !_vm.appointment.appointmentData.taskError && _vm.appointment.appointmentData.colorCode === 6,
    'color-7':
      !_vm.appointment.appointmentData.taskError && _vm.appointment.appointmentData.colorCode === 7,
    'color-8':
      !_vm.appointment.appointmentData.taskError && _vm.appointment.appointmentData.colorCode === 8,
    'color-9':
      !_vm.appointment.appointmentData.taskError && _vm.appointment.appointmentData.colorCode === 9,
    'color-10':
      !_vm.appointment.appointmentData.taskError && _vm.appointment.appointmentData.colorCode === 10
  }},[_c('div',{staticClass:"appointment-text"},[_vm._v(" "+_vm._s(_vm.appointment.appointmentData.text)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }