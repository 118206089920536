import { render, staticRenderFns } from "./AssignmentDetails.vue?vue&type=template&id=4e8d5273&scoped=true&"
import script from "./AssignmentDetails.vue?vue&type=script&lang=ts&"
export * from "./AssignmentDetails.vue?vue&type=script&lang=ts&"
import style0 from "./AssignmentDetails.vue?vue&type=style&index=0&id=4e8d5273&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e8d5273",
  null
  
)

export default component.exports