var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GenericFormDialog',{attrs:{"visible":_vm.resourcePlanning.showDialog,"model":_vm.resourcePlanning.dialogModel}}),(_vm.resourcePlanning.showFilterDialog)?_c('div',[_c('FilterDialog',{on:{"hide":function($event){return _vm.onHideFilterDialog()},"filter-changed":function($event){return _vm.onFilterChanged()}}})],1):_vm._e(),_c('GenericWizardDialog',{attrs:{"visible":_vm.resourcePlanning.showWizard,"model":_vm.resourcePlanning.wizardModel}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{class:_vm.showAssignmentDetails ||
          _vm.showActivityDetails ||
          _vm.showSubprojectDetails ||
          _vm.showProjectDetails
            ? 'col-9'
            : 'col-12'},[_c('ContentCard',{attrs:{"title":"Ressourcenplanung","cardIcon":"fas fa-list"},scopedSlots:_vm._u([{key:"body",fn:function(){return [(
                _vm.showAssignmentDetails ||
                _vm.showActivityDetails ||
                _vm.showSubprojectDetails ||
                _vm.showProjectDetails
              )?_c('div',{staticClass:"expandIcon",on:{"click":_vm.clearSelection}},[_c('em',{staticClass:"fas fa-angle-double-right"})]):_vm._e(),_c('div',[_c('ToolkitMenubar',[_c('ToolkitMenu',{attrs:{"model":_vm.resourcePlanning.menuFilter.model}}),_c('ToolkitMenu',{attrs:{"model":_vm.resourcePlanning.menuProject.model}}),_c('ToolkitMenu',{attrs:{"model":_vm.resourcePlanning.menuAssignments.model}}),_c('ToolkitMenu',{attrs:{"model":_vm.resourcePlanning.menuFavro.model}})],1),_c('ResourcePlanningGantt',{ref:"gantt",attrs:{"data":_vm.resourcePlanning.ganttModel.ganttData}})],1)]},proxy:true}])})],1),(
          _vm.showAssignmentDetails ||
          _vm.showActivityDetails ||
          _vm.showSubprojectDetails ||
          _vm.showProjectDetails
        )?_c('div',{staticClass:"col-3"},[(_vm.showAssignmentDetails)?_c('ContentCard',{staticClass:"ressource-detail-card",attrs:{"title":"Buchung","cardIcon":"fas fa-list","id":"assignmentDetailsCard"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"collapse-overlay",on:{"click":function($event){return _vm.toggleCardBody('assignmentDetailsCard', $event)}}}),_c('AssignmentDetails',{on:{"onAssignmentSaved":_vm.updateTask}})]},proxy:true}],null,false,4100422187)}):_vm._e(),(_vm.showActivityDetails)?_c('ContentCard',{staticClass:"ressource-detail-card",attrs:{"title":"Abteilung","cardIcon":"fas fa-list","id":"activityDetailsCard"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"collapse-overlay",on:{"click":function($event){return _vm.toggleCardBody('activityDetailsCard', $event)}}}),_c('ActivityDetails',{on:{"onActivitySaved":_vm.updateTask}})]},proxy:true}],null,false,789439573)}):_vm._e(),(_vm.showSubprojectDetails)?_c('ContentCard',{staticClass:"ressource-detail-card",attrs:{"title":"Teilprojekt","cardIcon":"fas fa-list","id":"subprojectDetailCard"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"collapse-overlay",on:{"click":function($event){return _vm.toggleCardBody('subprojectDetailCard', $event)}}}),_c('SubprojectDetails',{on:{"onSubProjectSaved":_vm.updateTask}})]},proxy:true}],null,false,1041598874)}):_vm._e(),(_vm.showProjectDetails)?_c('ContentCard',{staticClass:"ressource-detail-card",attrs:{"title":"Projekt","cardIcon":"fas fa-list","id":"projectDetailCard"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"collapse-overlay",on:{"click":function($event){return _vm.toggleCardBody('projectDetailCard', $event)}}}),_c('ProjectDetails',{on:{"onProjectSaved":_vm.updateTask}})]},proxy:true}],null,false,1577271806)}):_vm._e()],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }