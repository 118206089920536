var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"scheduler"}},[_c('DxScheduler',{ref:"timelineScheduler",attrs:{"time-zone":_vm.timeZone,"min":_vm.minAvailableDate,"max":_vm.maxAvaliableDate,"maxAppointmentsPerCell":"unlimited","views":_vm.timelineConfiguration.views,"data-source":_vm.timelineModel.tasks,"current-date":_vm.timelineConfiguration.currentDate,"groups":_vm.groups,"height":"100%","show-all-day-panel":true,"first-day-of-week":1,"start-day-hour":_vm.startDayHour,"end-day-hour":_vm.endDayHour,"cell-duration":_vm.cellDurationMinutes,"focus-state-enabled":true,"cross-scrolling-enabled":true,"current-view":_vm.currentView,"data-cell-template":"dataCellTemplate","date-cell-template":"dateCellTemplate","resource-cell-template":"resourceCellTemplate","appointment-template":"appointmentTemplate","on-appointment-context-menu":_vm.onAppointmentContextMenu},on:{"option-changed":function($event){return _vm.onOptionChanged($event)},"appointment-updated":function($event){return _vm.onAppointmentUpdated($event)},"appointment-click":function($event){return _vm.onAppointmentClicked($event)}},scopedSlots:_vm._u([{key:"appointmentTemplate",fn:function(ref){
var data = ref.data;
return [_c('AppointmentTemplate',{attrs:{"appointment-model":data}})]}},{key:"resourceCellTemplate",fn:function(ref){
var employee = ref.data;
return [_c('ResourceCell',{attrs:{"resource":employee}})]}},{key:"dataCellTemplate",fn:function(ref){
var cellData = ref.data;
return [_c('DataCell',{attrs:{"cell-data":cellData}})]}},{key:"dateCellTemplate",fn:function(ref){
var dateCellData = ref.data;
return [_c('DateCell',{attrs:{"cell-data":dateCellData}})]}}])},[_c('DxStateStoring',{attrs:{"enabled":true,"type":"localStorage","storage-key":"timeLineStorage"}}),_c('DxResource',{attrs:{"data-source":_vm.timelineModel.resources,"allow-multiple":false,"label":_vm.resourceLabel,"field-expr":_vm.taskRessourceIdKey}})],1),_c('DxContextMenu',{attrs:{"items":_vm.contextMenuItems,"width":200,"disabled":_vm.contextMenuDisabled,"target":_vm.contextMenuTarget,"on-item-click":_vm.onContextMenuItemClick,"item-template":"itemTemplateSlot"},scopedSlots:_vm._u([{key:"itemTemplateSlot",fn:function(ref){
var itemData = ref.data;
return [_c('ColorItemTemplate',{attrs:{"item-data":itemData}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }